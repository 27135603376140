<template>
  <div>
    <form
      style="padding-bottom: 200px"
      @submit.prevent="submitHandler"
      v-form-enter
      v-if="isLoading == false"
    >
      <div class="formgrid grid flex pb-3">
        <div class="field col-6">
          <h2>
            <template v-if="selectedObject">Edit</template
            ><template v-else>Add</template> Department Role
          </h2>
        </div>
        <div class="field col-6">
          <div class="p-inputgroup flex justify-content-end">
            <AInputSwitch v-model="input.active" class="green" />
          </div>
        </div>
        <Divider class="m-2 mb-5"></Divider>
        <div class="field col-12 sm:col-6">
          <label for="name">Role Name</label>
          <div class="p-inputgroup">
            <InputText
              placeholder="Role Name"
              v-model="input.title"
              class="green"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.input.title.required"
          >
            Role name is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label class="label">Department</label>
          <div class="p-inputgroup">
            <div class="flex align-items-center" v-if="selectedObject">
              {{ department_name }}
            </div>
            <div class="p-inputgroup" v-else>
              <Dropdown
                v-model="department"
                :options="departments"
                placeholder="department name"
                optionLabel="department_name"
                optionValue="uid"
                :class="selectedObject != null ? 'no-border-dd' : ''"
              />
            </div>
          </div>
        </div>
        <div v-if="selectedObject != null" class="field col-12 sm:col-6 mt-2">
          <label for="name">Assigned Users</label>
        </div>
      </div>
      <div v-for="u in assignedUsers" :key="u.uid">
        <div class="formgrid p-fluid grid flex align-items-center">
          <div class="col-6 field">
            <div class="flex align-items-center">
              <ProgressAvatar
                :image="u.profile_picture"
                :progress="u.progress_bar"
                :user="user"
                strokeColor="#6dd230"
                innerStrokeColor="#ffffff"
                :radius="25"
                class="mr-2"
              ></ProgressAvatar
              >{{ u.name }} {{ u.surname }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <Button type="submit"> SAVE </Button>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>

    <RocketLoad :full="true" v-else />
  </div>
</template>
<script>
import { state, fetchSettingsUsers } from "../../../services/data_service.js";
import { required } from "vuelidate/lib/validators";
export default {
  name: "DepartmentRoleSlider",
  components: {},
  data() {
    return {
      submitted: false,
      formOptions: [],
      myFiles: [],
      input: {
        title: null,
        active: true,
      },
      assignedUsers: null,
      department_name: null,
      department: null,
      profilePicture: "",
      user: [],
      isLoading: false,
      loadingText: "Loading...",
      inputIsVisible: false,
    };
  },
  validations: {
    input: {
      title: {
        required,
      },
    },
  },
  async mounted() {
    await this.loadData();
    if (this.selectedObject) {
      this.input = this.selectedObject.role;
      this.department = this.selectedObject.department_uid;
      this.department_name = this.departments.find(
        (department) => department.uid === this.selectedObject.department_uid
      ).department_name;
      this.assignedUsers = this.users.filter(
        (user) => user.primary_role_uid === this.selectedObject.role.uid
      );
    }
    if (this.selectedData) {
      this.department = this.selectedData.uid;
    }
  },
  methods: {
    async loadData() {
      await fetchSettingsUsers();
    },
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        this.loadingText = "Processing...";
        var form = new FormData();
        form.append("title", this.input.title);

        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              (!this.selectedObject
                ? "/v1/roles/department/" + this.department
                : "/v1/roles/departmentrole/" + this.selectedObject.uid),
            form,
            {
              headers: { "Content-type": "application/x-www-form-urlencoded" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.$toast.add({
              severity: "success",
              summary: "Department Role Added",
              life: 3000,
            });

            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
  computed: {
    user_uid() {
      return this.$store.getters.user.uid;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    selectedData() {
      return this.$store.getters.selectedData;
    },
    departments() {
      return state.rolesDepartment;
    },
    users() {
      return state.settingsUsers;
    },
  },
  watch: {},
};
</script>